import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { withTheme } from 'emotion-theming'

import { logout } from '../../utils/auth'

import { mediaQuery } from './styled'

import HamburgerReworked from './HamburgerReworked'

const Container = styled.div`
  display: none;
  ${mediaQuery.mobile} {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    margin: 0 auto 1.5rem;
    background-color: ${({ theme }) => theme.colors.black};
  }
`
const NavContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100vw;
  height: 3rem;
  position: relative;
  background-color: ${({ theme }) => theme.colors.black};
`
const CHLogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto auto auto 0;
`
//Leave here until we redesign the logo
// const CHLogoImageContainer = styled(Link)`
//   display: flex;
//   height: 100%;
//   margin: auto auto auto 1rem;
//   text-decoration: none;
// `
// const CHLogoImageStyled = styled(CinderhouseLogoWhite)`
//   height: 1.5rem;
//   width: 1.5rem;
//   margin: auto auto auto 0;
// `
const CHLogoText = styled(Link)`
  ${({ theme }) => theme.fonts.cinderhouseLogo_mobile};
  color: ${({ theme }) => theme.colors.white};
  margin: auto 0 auto 1rem;
  text-decoration: none;
`
const LinksContainer = styled.div`
  position: absolute;
  top: 3rem;
  left: 0;
  z-index: 10;
  padding: 1.5rem 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  transform: translateY(-100%);
`
const LinkContainer = styled.div`
  display: flex;
  width: 100%;
`
const LinkStyled = styled(Link)`
  ${({ theme }) => theme.fonts.navLinks};
  color: ${({ theme }) => theme.colors.white};
  margin: auto auto 1rem 1rem;

  :hover {
    border-bottom: 2px solid ${({ theme }) => theme.colors.red};
    padding-bottom: 2px;
  }
`
const LogoutStyled = styled.a`
  ${({ theme }) => theme.fonts.navLinks};
  color: ${({ theme }) => theme.colors.white};
  margin: auto auto 1rem 1rem;
`

const MemberLink = ({ navText }) => {
  return (
    <LinkContainer>
      <LinkStyled to="/members/courses">{navText.memberCoursesLink}</LinkStyled>
      <LinkStyled to="/members/podcasts">
        {navText.memberPodcastsLink}
      </LinkStyled>
      <LogoutStyled
        href="#logout"
        onClick={e => {
          logout()
          e.preventDefault()
        }}
      >
        {navText.memberLogoutLink}
      </LogoutStyled>
    </LinkContainer>
  )
}

const NoMemberLink = ({ navText }) => {
  return (
    <>
      <LinkContainer>
        <LinkStyled to="/#podcast">{navText.podcastLink}</LinkStyled>
      </LinkContainer>
      <LinkContainer>
        <LinkStyled to="/#calendar">{navText.tutorialLink}</LinkStyled>
      </LinkContainer>
      <LinkContainer>
        <LinkStyled to="/#community">{navText.communityLink}</LinkStyled>
      </LinkContainer>
    </>
  )
}

const Nav = ({ theme, member }) => {
  const cssClosed = css`
    animation: closeUpwards 0.75s forwards;

    @keyframes closeUpwards {
      from {
        transform: translateY(0%);
      }
      to {
        transform: translateY(-100%);
      }
    }
  `

  const cssOpen = css`
    animation: openDownwards 0.75s forwards;

    @keyframes openDownwards {
      from {
        transform: translateY(-100%);
      }
      to {
        transform: translateY(0);
      }
    }
  `

  const [open, setOpen] = useState(false)
  const [cssAnimation, setCssAnimation] = useState(css``)
  const clickHandler = open => {
    if (open) {
      setOpen(false)
      setCssAnimation(cssClosed)
    } else {
      setOpen(true)
      setCssAnimation(cssOpen)
    }
  }

  const navText = useStaticQuery(graphql`
    query NavMobTextQuery {
      navText: allAirtable(
        filter: { table: { eq: "siteText" }, data: { section: { eq: "nav" } } }
      ) {
        nodes {
          data {
            key
            text
          }
          recordId
        }
      }
    }
  `).navText.nodes.reduce((obj, node) => {
    obj[node.data.key] = node.data.text
    return { ...obj }
  }, {})

  return (
    <Container>
      <NavContainer
        css={css`
          margin-right: 1rem;
          z-index: 200;
        `}
      >
        <CHLogoContainer>
          <CHLogoText to="/">{navText.cinderhouse}</CHLogoText>
        </CHLogoContainer>
        <HamburgerReworked isOpen={open} clickHandler={clickHandler} />
      </NavContainer>

      <LinksContainer css={cssAnimation} onClick={() => clickHandler(open)}>
        <NoMemberLink navText={navText} />
      </LinksContainer>
    </Container>
  )
}

Nav.propTypes = {
  theme: PropTypes.object,
}

Nav.defaultProps = {
  theme: {},
}

export default withTheme(Nav)
