const theme = {
  timing: {
    fast: "0.4s",
    medium: "0.8s",
    slow: "1.5s"
  },
  colors: {
    black: "#191E25",
    blackDarker: "#0A0C0F",
    blackLighter: "#24282E",

    grey: "#24282E",
    greyDarker: "#191E25",
    greyLighter: "#66696D",

    red: "#D45938",
    redDarker: "#BF5032",
    redLighter: "#E18B74",

    blue: "#566882",
    blueDarker: "#0A0C0F",
    blueLighter: "#4D5E75",

    yellow: "#F8BC16",
    yellowDarker: "#DFA914",
    yellowLighter: "#FAD05C",

    white: "#f9f9f9",
    whiteLighter: "#fff",
    whiteDarker: "#c7c7c7"
  },

  fonts: {
    headline1: {
      fontFamily: "Oswald, sans-serif",
      fontSize: "3rem",
      lineHeight: "3rem",
      fontWeight: "700"
    },
    headline1_mobile: {
      fontSize: "1.75rem",
      lineHeight: "1.75rem"
    },
    headline2: {
      fontFamily: "Oswald, sans-serif",
      fontSize: "3rem",
      fontWeight: "700",
      textTransform: "uppercase",
      lineHeight: 1
    },
    headline2_tablet: {
      fontSize: "2.25rem"
    },
    headline2_mobile: {
      fontSize: "1.75rem"
    },
    headline3: {
      fontFamily: "Oswald, sans-serif",
      fontSize: "1.125rem",
      fontWeight: "700",
      textTransform: "uppercase"
    },
    headline3_mobile: {
      fontSize: "0.625rem",
      letterSpacing: "0.125rem",
      lineHeight: "0.7rem"
    },
    headline4: {
      fontFamily: "Oswald, sans-serif",
      fontSize: "0.625rem",
      fontWeight: "700",
      textTransform: "uppercase",
      letterSpacing: "0.125rem"
    },
    headline4_mobile: {
      fontSize: "0.625rem",
      letterSpacing: "0.1rem"
    },
    headline5: {
      fontFamily: "Oswald, sans-serif",
      fontSize: "0.625rem",
      fontWeight: "700",
      textTransform: "uppercase"
    },
    cinderhouseLogo: {
      fontFamily: "Oswald, sans-serif",
      fontSize: "0.75rem",
      fontWeight: "700"
    },
    cinderhouseLogo_mobile: {
      fontFamily: "Oswald, sans-serif",
      fontSize: "0.9rem",
      letterSpacing: "0.075rem",
      fontWeight: "700"
    },
    mainBodyText: {
      fontFamily: "Quattrocento, serif",
      fontSize: "0.725rem",
      lineHeight: 1.6,
      fontWeight: "400"
    },
    calloutsAndSubtitles: {
      fontFamily: "Quattrocento, serif",
      fontSize: "0.75rem",
      lineHeight: 1.4,
      fontWeight: "400"
    },
    calloutsAndSubtitles_mobile: {
      textOverflow: "ellipsis",
      fontSize: "1rem",
      lineHeight: 1.4
    },
    calloutsAndSubtitles_helper350: {},
    navLinks: {
      fontFamily: "Quattrocento, serif",
      fontSize: "0.75rem",
      fontWeight: "700",
      textTransform: "lowercase",
      textDecoration: "none"
    },
    cardAction: {
      fontFamily: "Oswald, sans-serif",
      fontSize: "0.625rem",
      fontWeight: "400",
      textTransform: "uppercase"
    },
    tags: {
      fontFamily: "Oswald, sans-serif",
      fontSize: "0.625rem",
      fontWeight: "700",
      textTransform: "uppercase",
      lineHeight: 1.53
    },
    calendarDay: {
      fontFamily: "Oswald, sans-serif",
      fontSize: "2rem",
      fontWeight: "700"
    },
    calendarDay_mobile: {
      fontSize: "1.5rem"
    }
  }
}

export { theme }
