import React from 'react'
import PropTypes from 'prop-types'

import { getProfile } from '../../utils/auth'

import './Layout.css'
import { withTheme, ThemeProvider } from 'emotion-theming'

import { theme } from './theme'
import NavDesktop from '../components/NavDesktop'
import NavMobile from '../components/NavMobile'
import { Global, css } from '@emotion/core'
import globalStyles from './globalStyles'

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]')
}

const Layout = ({ children }) => {
  const hasUser = Object.keys(getProfile()).length ? true : false
  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      <>
        <NavDesktop member={hasUser} />
        <NavMobile member={hasUser} />
        {children}
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTheme(Layout)
