import { theme } from "./theme"
import { mediaQuery } from "../components/styled"
import { css } from "@emotion/core"
const globalStyles = css`
  /*
      MEYER RESET START
      */
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    /* font-size: 100%;
    font: inherit;
    vertical-align: baseline; */
  }

  body {
    line-height: 1;
    font-weight: normal;
  }

  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  img {
    max-width: 100%;
    height: auto;
  }

  /*
    MEYER RESET END
    */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Oswald", sans-serif;
    font-weight: "700";
    text-transform: uppercase;
    color: ${theme.colors.blueDarker};
  }

  h1 + p,
  h2 + p,
  h3 + p,
  h4 + p,
  h5 + p,
  h6 + p {
    padding-top: 2rem;
  }

  p + h1,
  p + h2,
  p + h3,
  p + h4,
  p + h5,
  p + h6 {
    padding-top: 2rem;
  }

  p {
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }

  h1 {
    ${theme.fonts.headline1}
    ${mediaQuery.mobile} {
      ${theme.fonts.headline1_mobile}
    }
  }

  h2 {
    ${theme.fonts.headline2}
    ${mediaQuery.mobile} {
      ${theme.fonts.headline2_mobile}
    }
  }

  h3 {
    ${theme.fonts.headline3}
    ${mediaQuery.mobile} {
      ${theme.fonts.headline3_mobile}
    }
  }

  h4 {
    ${theme.fonts.headline4}
    ${mediaQuery.mobile} {
      ${theme.fonts.headline4_mobile}
    }
  }

  h5 {
    ${theme.fonts.headline5}
    ${mediaQuery.mobile} {
      ${theme.fonts.headline5_mobile}
    }
  }

  h6 {
    ${theme.fonts.headline6}
    ${mediaQuery.mobile} {
      ${theme.fonts.headline6_mobile}
    }
  }

  ul,
  ol {
    margin-left: 2rem;
    display: list-item;
    list-style-position: outside;
    ${mediaQuery.mobile} {
      margin-left: 2rem;
    }
  }

  li > p {
    margin-bottom: 0.5rem;
  }

  li:last-of-type > p {
    margin-bottom: 1.5rem;
  }

  li:marker {
    color: blue;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  blockquote {
    margin-left: 2rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-left: 0.5rem solid ${theme.colors.yellow};
    padding-left: 1rem;
    font-size: 1.6rem;
    line-height: 1;
    ${mediaQuery.mobile} {
      margin-left: 1.5rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  a {
    color: ${theme.colors.yellow};
    :hover {
      color: ${theme.colors.blue};
      transition: ${theme.timing.fast};
    }
  }

  hr {
    height: 0.1rem;
    background-color: ${theme.colors.yellow};
    width: 40%;
    margin: 2.5rem auto;
  }
`

export default globalStyles
