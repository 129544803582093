import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { theme } from "../layouts/theme"

const HamburgerUl = styled.ul`
  position: relative;
  cursor: pointer;
  width: 30px;
  height: 23px;
  list-style: none;
  margin: 0;

  li {
    width: 100%;
    height: 4px;
    transition: background-color 0.15s;
  }
  li:nth-of-type(1) {
    animation: outTop 0.15s backwards;
    animation-direction: reverse;
    @media only screen and (max-width: 699px) {
      background-color: ${theme.colors.whiteLighter};
    }
  }
  li:nth-of-type(2) {
    margin: 5px 0;
    animation: outMiddle 0.15s backwards;
    animation-direction: reverse;
  }
  li:nth-of-type(3) {
    animation: outBottom 0.15s backwards;
    animation-direction: reverse;
  }
`

const HamburgerReworked = ({ isOpen, clickHandler }) => {
  let openHamburger = css`
    z-index: 400;
    li:nth-of-type(1) {
      animation: inTop 0.15s forwards;
    }
    li:nth-of-type(2) {
      animation: inMiddle 0.15s forwards;
    }
    li:nth-of-type(3) {
      animation: inBottom 0.15s forwards;
    }
    @keyframes inMiddle {
      50% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(45deg);
      }
    }
    @keyframes outMiddle {
      50% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(45deg);
      }
    }
    @keyframes inTop {
      0% {
        transform: translateY(0px) rotate(0deg);
      }
      50% {
        transform: translateY(9px) rotate(0deg);
      }
      100% {
        transform: translateY(9px) rotate(135deg);
      }
    }
    @keyframes outTop {
      0% {
        transform: translateY(0px) rotate(0deg);
      }
      50% {
        transform: translateY(9px) rotate(0deg);
      }
      100% {
        transform: translateY(9px) rotate(135deg);
      }
    }
    @keyframes inBottom {
      0% {
        transform: translateY(0px) rotate(0deg);
      }
      50% {
        transform: translateY(-9px) rotate(0deg);
      }
      100% {
        transform: translateY(-9px) rotate(135deg);
      }
    }
    @keyframes outBottom {
      0% {
        transform: translateY(0px) rotate(0deg);
      }
      50% {
        transform: translateY(-9px) rotate(0deg);
      }
      100% {
        transform: translateY(-9px) rotate(135deg);
      }
    }
  `
  if (!isOpen) {
    openHamburger = css``
  }

  return (
    <div css={openHamburger}>
      <HamburgerUl
        onClick={() => clickHandler(isOpen)}
        css={css`
          z-index: 400;
        `}
      >
        <li
          css={css`
            background-color: ${isOpen
              ? theme.colors.red
              : theme.colors.whiteLighter};
          `}
        ></li>
        <li
          css={css`
            background-color: ${isOpen
              ? theme.colors.red
              : theme.colors.whiteLighter};
          `}
        ></li>
        <li
          css={css`
            background-color: ${isOpen
              ? theme.colors.red
              : theme.colors.whiteLighter};
          `}
        ></li>
      </HamburgerUl>
    </div>
  )
}

export default HamburgerReworked
