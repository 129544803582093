import styled from '@emotion/styled'

export const mediaQuery = {
  helper350: `@media (max-width: ${350}px)`,
  helper400: `@media (max-width: ${400}px)`,
  helper500: `@media (max-width: ${500}px)`,
  mobile: `@media (max-width: ${600}px)`,
  helper850: `@media (max-width: ${850}px)`,
  tablet: `@media (max-width: ${900}px)`,
  helper1100: `@media (min-width: ${1100}px)`,
  tabletLandscape: `@media (max-width: ${1200}px)`,
  desktop: `@media (max-width: ${1800}px)`,
  xlDesktopUp: `@media (min-width: ${1801}px)`,
}

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  margin: '0 auto';
`
export const CenterContainer = styled(Container)`
  width: 37.5rem;
  margin: 0;
  ${mediaQuery.tabletLandscape} {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }
  ${mediaQuery.tablet} {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }
  ${mediaQuery.mobile} {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }
`
export const Spacer = styled.div`
  flex: 1;
  ${mediaQuery.mobile} {
    display: none;
  }
`
