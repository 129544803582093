import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'

import { logout } from '../../utils/auth'

import CinderhouseLogoRefresh from '../../images/site/CinderhouseLogoRefresh'

import {
  Container as Containr,
  CenterContainer,
  Spacer,
  mediaQuery,
} from './styled'

const Container = styled(Containr)`
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  ${mediaQuery.tabletLandscape} {
    margin-top: 1.5rem;
  }
  ${mediaQuery.tablet} {
    margin-top: 1.5rem;
  }
  ${mediaQuery.mobile} {
    display: none;
  }
`
const SpacerBlackLineContainer = styled.div`
  flex: 1;
  ${mediaQuery.tabletLandscape} {
    visibility: hidden;
  }
  ${mediaQuery.tablet} {
    visibility: hidden;
  }
`
const SpacerBlackLine = styled.div`
  width: 90%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.blackLighter};
`
const CHLogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto auto auto 0;
`
const CHLogoImageContainer = styled(Link)`
  display: flex;
  width: 2rem;
  height: 2rem;
  text-decoration: none;
  position: relative;
`
const CHLogoImageStyled = styled(CinderhouseLogoRefresh)`
  height: 100%;
  width: 100%;
  margin: auto auto auto 0;
`
const CHLogoTextContainer = styled(Link)`
  display: flex;
  width: 8.75rem;
  height: 1.875rem;
  margin: auto 0;
  z-index: 1;
  text-decoration: none;
`
const CHLogoText = styled.span`
  ${({ theme }) => theme.fonts.cinderhouseLogo};
  color: ${({ theme }) => theme.colors.black};
  margin: auto 0;
`
const LinksContainer = styled.div`
  display: flex;
  height: 1.875rem;
  margin: auto 0 auto auto;
`
const LinkStyled = styled(Link)`
  ${({ theme }) => theme.fonts.navLinks};
  color: ${({ theme }) => theme.colors.black};
  margin: auto 0.9125rem auto 0;
  :last-of-type {
    margin: auto 0 auto 0;
  }
  :hover {
    border-bottom: 2px solid ${({ theme }) => theme.colors.red};
    padding-bottom: 2px;
  }
`
const LogoutStyled = styled.a`
  ${({ theme }) => theme.fonts.navLinks};
  color: ${({ theme }) => theme.colors.black};
  margin: auto 0 auto 0.3125rem;
`
const NoMemberLink = ({ navText }) => {
  return (
    <>
      <LinkStyled to="/#podcast">{navText.podcastLink}</LinkStyled>
      <LinkStyled to="/#community">{navText.communityLink}</LinkStyled>
      <LinkStyled to="/#calendar">{navText.tutorialLink}</LinkStyled>
    </>
  )
}
const MemberLink = ({ navText }) => {
  return (
    <>
      <LinkStyled to="/members/courses">{navText.memberCoursesLink}</LinkStyled>
      <LinkStyled to="/members/podcasts">
        {navText.memberPodcastsLink}
      </LinkStyled>
      <LogoutStyled
        href="#logout"
        onClick={e => {
          logout()
          e.preventDefault()
        }}
      >
        {navText.memberLogoutLink}
      </LogoutStyled>
    </>
  )
}

const Nav = ({ theme, member }) => {
  const navText = useStaticQuery(graphql`
    query NavTextQuery {
      navText: allAirtable(
        filter: { table: { eq: "siteText" }, data: { section: { eq: "nav" } } }
      ) {
        nodes {
          data {
            key
            text
          }
          recordId
        }
      }
    }
  `).navText.nodes.reduce((obj, node) => {
    obj[node.data.key] = node.data.text
    return { ...obj }
  }, {})

  return (
    <Container>
      <SpacerBlackLineContainer>
        <SpacerBlackLine />
      </SpacerBlackLineContainer>
      <CenterContainer>
        <CHLogoContainer>
          {/* <CHLogoImageContainer to="/"> */}
          {/* <CHLogoImageStyled /> */}
          {/* </CHLogoImageContainer> */}
          <CHLogoTextContainer to="/">
            <CHLogoText>{navText.cinderhouse}</CHLogoText>
          </CHLogoTextContainer>
        </CHLogoContainer>
        <LinksContainer>
          {member ? (
            <MemberLink navText={navText} />
          ) : (
            <NoMemberLink navText={navText} />
          )}
        </LinksContainer>
      </CenterContainer>

      <Spacer />
    </Container>
  )
}

Nav.propTypes = {
  theme: PropTypes.object,
}

Nav.defaultProps = {
  theme: {},
}

export default withTheme(Nav)
